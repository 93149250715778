body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom:60px;
}
html, body { height: 100% }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Photograph Signature";
  src: local("Photograph Signature"),
  url("/static/media/Photograph Signature.fd3e04eb.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Impact Label";
  src: local("Impact Label"),
  url("/static/media/Impact Label.36291f94.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueUltraLight";
  src: local("HelveticaNeueUltraLight"),
  url(/static/media/HelveticaNeueUltraLight.28fc2daf.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "impact";
  src: local("impact"),
  url(/static/media/impact.0294f828.ttf) format("truetype");
  font-weight: normal;
}




.portrait
{
    background-image: url(/static/media/Portrait.4701437e.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    cursor: pointer;
}

.portrait:hover{
    background-image: url(/static/media/Portrait_logo.6f16b339.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    content: "Portrait";


}
.legendPortrait{
    color: transparent;
    font-size: 30px;
    line-height: 1.5em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

}


.legendPortrait:hover {
    font-family: 'HelveticaNeueUltraLight';
    color: black;
    font-size: 70px;
    text-shadow: 2px 2px 2px #ffffff;
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}


.stilllife
{
    background-image: url(/static/media/Still_life.a9f80b75.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.stilllife:hover{
    background-image: url(/static/media/Still_life_logo.f8e208f8.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
}


.travel
{
    background-image: url(/static/media/travel_N9A9788.91b53281.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    cursor: pointer;
}

.travel:hover{
    background-image: url(/static/media/travel_logo.c036564f.jpg);
    height: 805px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;

}

.contact
{
    background-image: url(/static/media/contact_logo.52d2d5ca.jpg);
    height: 815px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    cursor: pointer;
}

.contact:hover{
    background-image: url(/static/media/contact.a48aeeff.jpg);
    height: 815px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
}

/********* Hover image et texte *******/
/********* Superposition simple de texte sur une image *******/
/* Conteneur principal */
/********* Superposition simple de texte sur une image *******/
/* Conteneur principal */
.overlay-image {
    position: relative;
    height: 815px;
    width: 1006px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;

}
/* Image originale */
.overlay-image .image {
    display: block;
    width: 100%;
    height: auto;

}
/* Texte original */
.overlay-image .text {
    font-family: 'HelveticaNeueUltraLight';
    color: black;
    font-size: 70px;
    line-height: 1.5em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}


.overlay-image .textcontact {
    font-family: 'HelveticaNeueUltraLight';
    color: black;
    font-size: 40px;
    line-height: 1.5em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-decoration: none;
}
/********* Hover image et texte *******/
/* Overlay */
.overlay-image .hover {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;

}
/* Apparition overlay sur passage souris */
.overlay-image:hover .hover {
    opacity: 1;

}

/********* Hover background et texte uniquement *******/
.overlay-image .normal {
    transition: .5s ease;

}
.overlay-image:hover .normal {
    opacity: 0;

}
.overlay-image .hover {

}
.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    align-items: center;
    z-index: 3;
}

.dropdown {


}

.dropdown-content {
    left: 0;
    right: 0;
    display: none;
    position: absolute;
    background-color: transparent;
margin: 0;
    z-index: 99999;
    text-align: center;
    font-family: 'HelveticaNeueUltraLight';
    font-weight: bold;
    font-size: large;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;


}

.dropdown-content a:hover {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;

    color: black;}

.dropdown:hover .dropdown-content {display: block;

    min-width:100%;}

.dropdown:hover .dropbtn {

    background-color: transparent;}

.wrapper {
    min-height: 100%;
    left: 0;
    right: 0;
}

.hamburger-inner {
    background-color: black;
}
.hamburger-inner::before{
    background-color: black;
}
.hamburger-inner::after{
    background-color: black;
}

.accordion{
    left: 50%;
}

.dropContent:hover
{
    display: block;
}

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    align-items: center;

}

.dropdown {


}
.header {
    z-index: 1;
}

.dropdown:hover .dropdown-content {display: block;   min-width:100%;}

.dropdown:hover .dropbtn {background-color: transparent;}

.wrapper {
    min-height: 100%;
    left: 0;
    right: 0;
}
.imageP
{
    position: relative;
    margin-right: auto;
    margin-left: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;
    justify-content: center;
}
