@import "~hamburgers/dist/hamburgers.css";

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    align-items: center;
    z-index: 3;
}

.dropdown {


}

.dropdown-content {
    left: 0;
    right: 0;
    display: none;
    position: absolute;
    background-color: transparent;
margin: 0;
    z-index: 99999;
    text-align: center;
    font-family: 'HelveticaNeueUltraLight';
    font-weight: bold;
    font-size: large;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;


}

.dropdown-content a:hover {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;

    color: black;}

.dropdown:hover .dropdown-content {display: block;

    min-width:100%;}

.dropdown:hover .dropbtn {

    background-color: transparent;}

.wrapper {
    min-height: 100%;
    left: 0;
    right: 0;
}

.hamburger-inner {
    background-color: black;
}
.hamburger-inner::before{
    background-color: black;
}
.hamburger-inner::after{
    background-color: black;
}

.accordion{
    left: 50%;
}

.dropContent:hover
{
    display: block;
}

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    align-items: center;

}

.dropdown {


}
.header {
    z-index: 1;
}

.dropdown:hover .dropdown-content {display: block;   min-width:100%;}

.dropdown:hover .dropbtn {background-color: transparent;}

.wrapper {
    min-height: 100%;
    left: 0;
    right: 0;
}