body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom:60px;
}
html, body { height: 100% }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Photograph Signature";
  src: local("Photograph Signature"),
  url("./fonts/Photograph Signature.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Impact Label";
  src: local("Impact Label"),
  url("./fonts/Impact Label.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueUltraLight";
  src: local("HelveticaNeueUltraLight"),
  url("./fonts/HelveticaNeueUltraLight.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "impact";
  src: local("impact"),
  url("./fonts/impact.ttf") format("truetype");
  font-weight: normal;
}

